import { MutableRefObject, useCallback, useEffect, useRef } from "react";

import axios, { AxiosError, AxiosRequestConfig } from "axios";
import Router from "next/router";

import axiosInstance from "@lib/api-fetcher/axiosInstance";
import { ROUTES } from "@utils/constants";

import { BYPASS_ENDPOINTS, NOT_AUTHENTICATED_RESPONSE_STATUS_CODE } from "../constants";
import updateToken from "../services/updateToken";
import type { TAuthData } from "../types";
import addRetryToRequest from "../utils/addRetryToRequest";
import addTokenToRequest from "../utils/addTokenToRequest";
import isRetryRequest from "../utils/isRetryRequest";

const shouldBypass = (config?: AxiosRequestConfig) => !config?.url || BYPASS_ENDPOINTS.includes(config.url);

const useAuthInterceptors = (authData: TAuthData | null, setAuthData: (authData: TAuthData | null) => void): void => {
  const requestInterceptor = useCallback(
    (config: AxiosRequestConfig) => {
      if (!authData || isRetryRequest(config)) {
        return config;
      }

      return addTokenToRequest(config, authData);
    },
    [authData]
  );

  const responseErrorInterceptor = useCallback(
    async (error: AxiosError) => {
      if (error.response?.status !== NOT_AUTHENTICATED_RESPONSE_STATUS_CODE || shouldBypass(error.config)) {
        return Promise.reject(error);
      }

      if (!authData || isRetryRequest(error.config)) {
        // Endpoint requires authentication and there is no previous auth data or we already tried getting new token
        setAuthData(null);
        Router.push(ROUTES.LOGIN);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new axios.Cancel("Authentication required.");
      }

      const updatedAuthData = await updateToken(authData);

      if (!updatedAuthData) {
        // Access token update failed - need to show login page
        setAuthData(null);
        Router.push(ROUTES.LOGIN);
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new axios.Cancel("Authentication required.");
      }

      setAuthData(updatedAuthData);

      // Retry failed request with new token
      const updatedConfig = addRetryToRequest(addTokenToRequest(error.config, updatedAuthData));
      return axiosInstance.request(updatedConfig);
    },
    [authData, setAuthData]
  );

  const reqInterceptorHandle: MutableRefObject<number | null> = useRef(null);
  const resInterceptorHandle: MutableRefObject<number | null> = useRef(null);

  const updateInterceptors = useCallback(() => {
    if (reqInterceptorHandle.current !== null) {
      axiosInstance.interceptors.request.eject(reqInterceptorHandle.current);
    }
    if (resInterceptorHandle.current !== null) {
      axiosInstance.interceptors.response.eject(resInterceptorHandle.current);
    }
    reqInterceptorHandle.current = axiosInstance.interceptors.request.use(requestInterceptor);
    resInterceptorHandle.current = axiosInstance.interceptors.response.use(
      (response) => response,
      responseErrorInterceptor
    );
  }, [requestInterceptor, responseErrorInterceptor]);

  useEffect(() => {
    updateInterceptors();
  }, [updateInterceptors]);
};
export default useAuthInterceptors;
