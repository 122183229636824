import type { ComponentMultiStyleConfig } from "@chakra-ui/react";

import { focusable } from "@lib/theme/mixins";
import responsiveStyle from "@lib/theme/responsiveStyle";

const Carousel: ComponentMultiStyleConfig = {
  parts: ["carousel", "slide"],
  baseStyle: {
    carousel: {
      "--swiper-pagination-bullet-inactive-opacity": "1",
      "--swiper-pagination-bullet-opacity": "1",
      "--swiper-pagination-bullet-size": "0.6875rem",
      "--swiper-pagination-bullet-inactive-color": "colors.grey.light",
      "--swiper-pagination-color": "colors.red.lead",
      "--swiper-navigation-size": "1.5rem",
      "--swiper-navigation-color": "colors.grey.light",
      position: "relative",
      ".swiper-wrapper": {
        // Offsetting first slide while js initializes to prevent flickering
        transform: "translate3d(-100%, 0px, 0px)",
      },
      ".swiper-pagination-bullet": {
        width: responsiveStyle({ mobile: "0.5rem", desktop: "0.6875rem" }),
        height: responsiveStyle({ mobile: "0.5rem", desktop: "0.6875rem" }),
        ...focusable,
        _hover: {
          background: "white",
        },
      },
      ".swiper-pagination-bullet-active": {
        _hover: {
          background: "red.lead",
        },
      },
      ".swiper-button-prev, .swiper-button-next": {
        display: { base: "none", md: "flex" },
        ...focusable,
        _hover: {
          color: "white",
        },
      },
      ".swiper-slide": {
        // Possible fix for flickering in safari: https://github.com/nolimits4web/swiper/issues/3527
        transform: "translate3d(0, 0, 0);",
      },
    },
    slide: {
      position: "relative",
      "& > .fresnel-container": {
        position: "relative",
        height: "100%",
      },
    },
    slideLink: {
      position: "relative",
      display: "block",
      height: "100%",
      "& > .fresnel-container": {
        position: "relative",
        height: "100%",
      },
    },
    videoSlide: {
      position: "relative",
      height: "100%",
      "> video": {
        position: "absolute",
        inset: "0",
        objectFit: "cover",
        width: 0,
        height: 0,
        minWidth: "100%",
        minHeight: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        pointerEvents: "none",
      },
    },
    overlay: {
      position: "absolute",
      bottom: "2rem",
      left: "2rem",
      fontSize: "1.875rem",
      color: "white",
      fontWeight: "300",
      width: { base: "33%", md: "50%" },
    },
  },
};
export default Carousel;
