import { createBreakpoints, BaseBreakpointConfig } from "@chakra-ui/theme-tools";

import { breakpoints } from "../media";

const chakraBreakpoints: BaseBreakpointConfig = (() => {
  const convertedBreakpoints = {};
  Object.entries(breakpoints).forEach(([key, val]) => {
    convertedBreakpoints[key] = `${val / 16}em`;
  });
  return convertedBreakpoints as BaseBreakpointConfig;
})();

export default createBreakpoints(chakraBreakpoints);
