export const TRANSLATIONS = "/translations";
export const TOKEN_REFRESH = "/token/refresh";
export const FAQ = "/faq";
export const FEATURES = "/features";
export const AUTH_FACEBOOK = "/auth/facebook";
export const AUTH = "/auth";
export const AUTH_SEND_VERIFICATION_EMAIL = "/user/verification";
export const USER = "/user";
export const USER_PASSWORD = "/user/password";
export const USER_SETTINGS = "/user/settings";
export const USER_ADDRESSESS = "/user/addresses";
export const USER_INVOICE_ADDRESSES = "/user/invoice-addresses";
export const USER_COMPANY_INVOICE_ADDESS = "/user/invoice-addresses/company";
export const USER_PRIVATE_INVOICE_ADDESS = "/user/invoice-addresses/private";
export const USER_INVOICES_DOWNLOAD = "/user/invoices/download";
export const USER_INVOICES = "/user/invoices";
export const USER_SHIPMENTS = "/shipments";
export const REGISTER = "/user/register";
export const VERIFY = "/user/verify";
export const COUNTRIES = "/countries";
export const LOCKERS = "/lockers";
export const PASSWORD_RESET = "/user/password/reset";
export const DIMENSIONS = "/dimensions";
export const OFFERS = "/offers";
export const DEFAULT_ADDRESS = "/user/address";
export const ADDRESS_BOOK = "/address-book";
export const SHIPMENT = "/shipment";
export const PAYMENT_METHODS = "/payments";
export const PAYMENT_STATUS = "/payments/status";
export const SHIPMENTS_LABELS = "/shipments/labels";
export const SHIPMENTS_PAY = "/shipments/pay";
export const SHIPMENTS_COUPON_APPLY = "/shipments/coupon/apply";
export const SHIPMENTS_COUPONS_RELEASE = "/shipments/coupons/release";
export const PICKUP_TIMEFRAMES = "/pickup-timeframes";
export const ADHOCK_TOKEN = "/adhock-token";

export const getBanners = (zone: string): string => `/banners/${zone}`;
export const getUserAddressById = (addressId: string | number): string => `${USER_ADDRESSESS}/${addressId}`;
export const getUserInvoiceAddressByType = (type: "company" | "private"): string => `${USER_INVOICE_ADDRESSES}/${type}`;
export const getUserDataAddressBookById = (id: string | number): string => `${ADDRESS_BOOK}/${id}`;
export const getUserShipmentsCopyById = (id: string | number): string => `/shipment/${id}/copy`;
export const getAffiliateDataById = (id: string): string => `/affiliate/${id}`;
export const getShipmentById = (id: string): string => `/shipment/${id}`;
export const getShipmentByEncryptedId = (id: string): string => `/shipment/hashed/${id}`;
export const getRdlOrderBarcode = (id: string): string => `/barcode/${id}`;
