const I18NextHttpBackend = require("i18next-http-backend");

module.exports = {
  i18n: {
    defaultLocale: "default",
    locales: ["en", "lt", "lv", "ee", "ru", "default"],
    localeDetection: false,
  },
  backend: {},
  serializeConfig: false,
  use: [I18NextHttpBackend],
  react: {
    useSuspense: false,
  },
};
