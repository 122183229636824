import { i18n } from "next-i18next";
import { AnySchema } from "yup";

import { DeliveryMethod, OrderFields } from "../../constants";

const shipmentRequiredFields = [
  OrderFields.SENDER_PHONE_NUMBER,
  OrderFields.SENDER_EMAIL,
  OrderFields.RETURN_LOCKER,
  OrderFields.RECEIVER_PHONE_NUMBER,
];
const shipmentNonDomesticRequiredFields = [OrderFields.SENDER_NAME];
const shipmentPudoToPudoRequiredFields = [OrderFields.RECEIVER_LOCKER];
const shipmentPudoToPudoNonDomesticRequiredFields = [OrderFields.RECEIVER_NAME];
const shipmentPudoToDoorRequiredFields = [
  OrderFields.RECEIVER_NAME,
  OrderFields.RECEIVER_PHONE_NUMBER,
  OrderFields.RECEIVER_STREET,
  OrderFields.RECEIVER_CITY,
  OrderFields.RECEIVER_ZIP_CODE,
];

const getRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (shipmentRequiredFields.includes(fieldName)) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }
  return schema;
};

const getNonDomesticRequiredFieldSchema = (
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod,
  schema: AnySchema
) => {
  if (
    shipmentNonDomesticRequiredFields.includes(fieldName) &&
    (deliveryMethod === DeliveryMethod.PudoToPudo || deliveryMethod === DeliveryMethod.PudoToDoor)
  ) {
    return schema.when(OrderFields.IS_DOMESTIC, (isDomestic: boolean, domesticSchema: AnySchema) =>
      !isDomestic ? domesticSchema.required(i18n?.t("order-message-field_is_required")) : domesticSchema
    );
  }
  return schema;
};

const getDeliveryMethodRequiredFieldSchema = (
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod,
  schema: AnySchema
) => {
  if (shipmentPudoToPudoRequiredFields.includes(fieldName) && deliveryMethod === DeliveryMethod.PudoToPudo) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }

  if (shipmentPudoToDoorRequiredFields.includes(fieldName) && deliveryMethod === DeliveryMethod.PudoToDoor) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }
  if (shipmentPudoToPudoNonDomesticRequiredFields.includes(fieldName) && deliveryMethod === DeliveryMethod.PudoToPudo) {
    return schema.when(OrderFields.IS_DOMESTIC, (isDomestic: boolean, domesticSchema: AnySchema) =>
      !isDomestic ? domesticSchema.required(i18n?.t("order-message-field_is_required")) : domesticSchema
    );
  }
  return schema;
};

const getShipmentRequiredFieldSchema = (
  schema: AnySchema,
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod
): AnySchema => {
  let schemaWithValidation = schema;

  schemaWithValidation = getRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getNonDomesticRequiredFieldSchema(fieldName, deliveryMethod, schemaWithValidation);
  schemaWithValidation = getDeliveryMethodRequiredFieldSchema(fieldName, deliveryMethod, schemaWithValidation);

  return schemaWithValidation;
};
export { getShipmentRequiredFieldSchema };
