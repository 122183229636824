/* eslint-disable @typescript-eslint/naming-convention */
import type { SystemStyleObject } from "@chakra-ui/react";

import { inputControlFocusable } from "@lib/theme/mixins";

const Radio: SystemStyleObject = {
  baseStyle: {
    container: {
      ".chakra-radio__input": {
        ...inputControlFocusable(".chakra-radio__control"),
      },
    },
    control: {
      bg: "white",
      border: "1px solid",
      borderColor: "grey.mid",
      borderRadius: "50%",
      color: "dpd-black",
      _checked: {
        bg: "white",
        color: "dpd-black",
        borderColor: "grey.mid",
        _hover: {
          bg: "white",
          borderColor: "grey.mid",
        },
        _disabled: {
          bg: "grey.light",
          color: "grey.mid",
          borderColor: "grey.mid",
        },
      },

      _disabled: {
        bg: "grey.light",
        color: "grey.mid",
        borderColor: "grey.mid",
      },
      _invalid: {
        borderColor: "red.lead",
        boxShadow: "error",
        _disabled: {
          bg: "grey.light",
          color: "grey.mid",
        },
      },
    },
  },
  variants: {
    dark: {
      control: { color: "dpd-black", _checked: { color: "dpd-black" } },
    },
    red: {
      control: { color: "red.lead", _checked: { color: "red.lead" } },
      label: {
        _checked: { color: "red.lead" },
      },
    },
  },
  sizes: {
    sm: {
      control: {
        width: "1rem",
        height: "1rem",
        _before: { width: "0.625rem", height: "0.625rem" },
        _checked: { _before: { width: "0.625rem", height: "0.625rem" } },
      },
      label: { fontSize: "inherit" },
    },

    md: {
      control: {
        width: "1.625rem",
        height: "1.625rem",
        _before: { width: "1rem", height: "1rem" },
        _checked: { _before: { width: "1rem", height: "1rem" } },
      },
      label: { fontSize: "inherit" },
    },

    lg: {
      control: {
        width: "1.875rem",
        height: "1.875rem",
        _before: { width: "1.125rem", height: "1.125rem" },
        _checked: { _before: { width: "1.125rem", height: "1.125rem" } },
      },
      label: { fontSize: "inherit" },
    },
  },

  defaultProps: {
    size: "md",
    variant: "dark",
  },
};

export default Radio;
