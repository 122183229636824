import { FC } from "react";

import FeatureContext from "./contexts/FeatureContext";

type TFeatureProviderProps = {
  features: Array<string>;
};

const FeatureProvider: FC<TFeatureProviderProps> = ({ children, features }) => (
  <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>
);

export default FeatureProvider;
