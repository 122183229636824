import { i18n } from "next-i18next";
import * as yup from "yup";
import type { BooleanSchema, AnySchema } from "yup";

import { Router } from "@lib/router";

import { InvoiceType, OrderFields, OrderStep } from "../../constants";

const summaryPrivateInvoiceRequiredFields = [OrderFields.INVOICE_EMAIL];
const summaryCompanyInvoiceRequiredFields = [
  OrderFields.INVOICE_COMPANY_NAME,
  OrderFields.INVOICE_COMPANY_EMAIL,
  OrderFields.INVOICE_REGISTRY_CODE,
  OrderFields.INVOICE_REGISTRATION_ADDRESS,
];

const getInvoiceRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (Router.defaultLocale !== "lt") {
    summaryPrivateInvoiceRequiredFields.push(OrderFields.INVOICE_NAME);
  }

  if (
    summaryPrivateInvoiceRequiredFields.includes(fieldName) ||
    summaryCompanyInvoiceRequiredFields.includes(fieldName)
  ) {
    return schema.when(OrderFields.INVOICE_TYPE, (invoiceType: InvoiceType, invoiceSchema: AnySchema) => {
      if (
        (invoiceType === InvoiceType.PRIVATE && summaryPrivateInvoiceRequiredFields.includes(fieldName)) ||
        (invoiceType === InvoiceType.COMPANY && summaryCompanyInvoiceRequiredFields.includes(fieldName))
      ) {
        return invoiceSchema.required(i18n?.t("order-message-field_is_required"));
      }

      return invoiceSchema;
    });
  }
  return schema;
};

const getPaymentMethodRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (fieldName === OrderFields.PAYMENT_METHOD) {
    return schema.when(OrderFields.PRICE, (price: number, priceSchema: AnySchema) =>
      price > 0 ? priceSchema.required(i18n?.t("order-summary-message-payment_method_is_required")) : priceSchema
    );
  }
  return schema;
};

const getSummaryRequiredFieldSchema = (schema: AnySchema, fieldName: OrderFields): AnySchema => {
  let schemaWithValidation = schema;

  schemaWithValidation = getInvoiceRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getPaymentMethodRequiredFieldSchema(fieldName, schemaWithValidation);

  return schemaWithValidation;
};

const getTermsAgreementRequiredSchema = (): BooleanSchema =>
  yup.bool().when(OrderFields.CURRENT_STEP, (currentStep: OrderStep, schema: AnySchema) => {
    if (currentStep === OrderStep.Summary) {
      return schema
        .required(i18n?.t("order-summary-message-you_must_agree_terms_and_conditions"))
        .oneOf([true], i18n?.t("order-summary-message-you_must_agree_terms_and_conditions"));
    }
    return schema;
  });

export { getSummaryRequiredFieldSchema, getTermsAgreementRequiredSchema };
