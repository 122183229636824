import * as Sentry from "@sentry/nextjs";

/**
 * Sentry is disabled in client side. Only server side error will be reported
 */

Sentry.init({
  dsn: null,
  enabled: false,
});
