import { i18n } from "next-i18next";
import { AnySchema } from "yup";

import { Router } from "@lib/router";

import { DeliveryMethod, OrderFields } from "../../constants";

const senderRequiredFields = [
  OrderFields.SENDER_NAME,
  OrderFields.SENDER_PHONE_NUMBER,
  OrderFields.SENDER_EMAIL,
  OrderFields.SENDER_STREET,
  OrderFields.SENDER_CITY,
  OrderFields.SENDER_ZIP_CODE,
  OrderFields.LABEL_PRINT,
  OrderFields.PICKUP_DATE,
];
const senderOriginOrderRequiredFields = [OrderFields.PICKUP_TIME_FROM, OrderFields.PICKUP_TIME_TILL];
const senderNonDomesticRequiredFields = [OrderFields.SENDER_NAME];
const senderDoorToDoorRequiredFields = [OrderFields.SENDER_NAME];
const senderDomesticEstonianFields = [OrderFields.SENDER_NAME];
const senderContactPersonRequiredFields = [OrderFields.CONTACT_PERSON_PHONE_NUMBER, OrderFields.CONTACT_PERSON_EMAIL];
const senderContactPersonDoorToDoorRequiredFields = [OrderFields.CONTACT_PERSON_NAME];
const senderContactPersonNonDomesticRequiredFields = [OrderFields.CONTACT_PERSON_NAME];

const getRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (senderRequiredFields.includes(fieldName)) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }
  return schema;
};

const getOriginOrderRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (senderOriginOrderRequiredFields.includes(fieldName)) {
    return schema.when(OrderFields.IS_FROM_ORIGIN, (isFromOrigin: boolean, originSchema: AnySchema) =>
      isFromOrigin ? originSchema.required(i18n?.t("order-message-field_is_required")) : originSchema
    );
  }
  return schema;
};

const getNonDomesticRequiredFieldSchema = (fieldName: OrderFields, schema: AnySchema) => {
  if (senderNonDomesticRequiredFields.includes(fieldName)) {
    return schema.when(OrderFields.IS_DOMESTIC, (isDomestic: boolean, domesticSchema: AnySchema) =>
      !isDomestic ? domesticSchema.required(i18n?.t("order-message-field_is_required")) : domesticSchema
    );
  }
  return schema;
};

const getDomesticEstonianFieldSchema = (fieldName: OrderFields, deliveryMethod: DeliveryMethod, schema: AnySchema) => {
  if (senderDomesticEstonianFields.includes(fieldName)) {
    return schema.when(OrderFields.IS_DOMESTIC, (isDomestic: boolean, domesticSchema: AnySchema) =>
      isDomestic && Router.defaultLocale === "ee" && deliveryMethod === DeliveryMethod.DoorToPudo
        ? domesticSchema.required(i18n?.t("order-message-field_is_required"))
        : domesticSchema
    );
  }
  return schema;
};
const getContactPersonRequiredFieldSchema = (
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod,
  schema: AnySchema
) => {
  if (
    senderContactPersonRequiredFields.includes(fieldName) ||
    senderContactPersonDoorToDoorRequiredFields.includes(fieldName) ||
    senderContactPersonNonDomesticRequiredFields.includes(fieldName)
  ) {
    return schema.when(
      [OrderFields.IS_DOMESTIC, OrderFields.SENDER_AS_CONTACT_PERSON],

      ((isDomestic: boolean, isSenderAsContactPerson: boolean, contactPersonSchema: AnySchema) => {
        if (isSenderAsContactPerson) {
          return contactPersonSchema;
        }
        if (
          deliveryMethod === DeliveryMethod.DoorToDoor &&
          senderContactPersonDoorToDoorRequiredFields.includes(fieldName)
        ) {
          return contactPersonSchema.required(i18n?.t("order-message-field_is_required"));
        }
        if (!isDomestic && senderContactPersonNonDomesticRequiredFields.includes(fieldName)) {
          return contactPersonSchema.required(i18n?.t("order-message-field_is_required"));
        }
        if (senderContactPersonRequiredFields.includes(fieldName)) {
          return contactPersonSchema.required(i18n?.t("order-message-field_is_required"));
        }
        return contactPersonSchema;
      }) as any // yup typing issue https://github.com/jquense/yup/issues/1417
    );
  }
  return schema;
};

const getDeliveryMethodRequiredFieldSchema = (
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod,
  schema: AnySchema
) => {
  if (senderDoorToDoorRequiredFields.includes(fieldName) && deliveryMethod === DeliveryMethod.DoorToDoor) {
    return schema.required(i18n?.t("order-message-field_is_required"));
  }
  return schema;
};

const getSenderRequiredFieldSchema = (
  schema: AnySchema,
  fieldName: OrderFields,
  deliveryMethod: DeliveryMethod
): AnySchema => {
  let schemaWithValidation = schema;

  schemaWithValidation = getRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getOriginOrderRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getNonDomesticRequiredFieldSchema(fieldName, schemaWithValidation);
  schemaWithValidation = getDomesticEstonianFieldSchema(fieldName, deliveryMethod, schemaWithValidation);
  schemaWithValidation = getContactPersonRequiredFieldSchema(fieldName, deliveryMethod, schemaWithValidation);
  schemaWithValidation = getDeliveryMethodRequiredFieldSchema(fieldName, deliveryMethod, schemaWithValidation);

  return schemaWithValidation;
};

export { getSenderRequiredFieldSchema };
