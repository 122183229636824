import { FC } from "react";

import { createMedia } from "@artsy/fresnel";

export const breakpoints = {
  sm: 0,
  md: 769,
  lg: 992,
  xl: 1200,
};
const AppMedia = createMedia({
  breakpoints,
});
export const mediaStyle = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;

export const Mobile: FC = ({ children }) => <Media lessThan="md">{children}</Media>;
export const Desktop: FC = ({ children }) => <Media greaterThanOrEqual="md">{children}</Media>;
